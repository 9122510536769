<template lang="pug">
    .kt-form
      .kt-wizard-v1__content(data-ktwizard-type="step-content" data-ktwizard-state="current")
        .kt-form__section.kt-form__section--first
          .kt-wizard-v1__form
            .row.justify-content-center.mb-5
              .company-pic-wrap
                v-image-select(
                  square
                  :size="148"
                  :value="company.companyLogoUrl"
                  @input="cropSuccess")
                span.form-text.invalid.invalid-logo.absolute {{ 'file' | errorText }}
            .row.justify-content-center
              .col-md-6
                .form-group
                  label.required {{ 'pages.setup_wizard.company_name' | translate}}
                  input.form-control(
                    name="company-name"
                    :placeholder="'pages.setup_wizard.example_company' | translate"
                    v-model="company.companyName")
                  span.form-text.invalid.absolute {{ 'companyName' | errorText }}
            .row.justify-content-center(v-if="isSetupPage")
              .col-md-6
                .form-group
                  label.required {{ 'pages.setup_wizard.default_language'  | translate}}
                  v-select(
                    name="language"
                    trackBy="title"
                    single
                    hideBottomMargin
                    :placeholder="'ui.dropdown.please_select' | translate"
                    :options="languages"
                    v-model="company.defaultLanguage")
                  span.form-text.invalid.absolute {{ 'defaultLanguageId' | errorText }}
            .row
              .col-md-6
                .form-group
                  label {{ 'pages.setup_wizard.email' | translate}}
                  input.form-control(
                    name="admin-email"
                    :placeholder="'pages.setup_wizard.example_email' | translate"
                    v-model="company.adminEmail")
                  span.form-text.invalid.absolute {{ 'adminEmail' | errorText }}
              .col-md-6
                .form-group
                  label {{ 'pages.setup_wizard.contact_phone_number'  | translate}}
                  input.form-control(
                    name="phone"
                    :placeholder="'pages.setup_wizard.example_phone' | translate"
                    v-model="company.phoneNumber")
                  span.form-text.invalid.absolute {{ 'phoneNumber' | errorText }}
            .form-group
              label {{ 'pages.setup_wizard.address' | translate}}
              input.form-control(
                name="address"
                :placeholder="'pages.setup_wizard.example_str' | translate"
                v-model="company.address")
              span.form-text.invalid.absolute {{ 'address' | errorText }}
            .row
              .col-md-6
                .form-group
                  label {{ 'pages.setup_wizard.postcode' | translate}}
                  input.form-control(
                    name="postcode"
                    :placeholder="'pages.setup_wizard.example_postcode' | translate"
                    v-model="company.postCode")
                  span.form-text.invalid.absolute {{ 'postCode' | errorText }}
              .col-md-6
                .form-group
                  label {{ 'pages.setup_wizard.city' | translate}}
                  input.form-control(
                    name="city"
                    :placeholder="'pages.setup_wizard.example_city' | translate"
                    v-model="company.city")
                  span.form-text.invalid.absolute {{ 'city' | errorText }}
            .row
              .col-md-6
                .form-group
                  label {{ 'pages.setup_wizard.state' | translate}}
                  input.form-control(
                    name="state"
                    :placeholder="'pages.setup_wizard.example_state' | translate"
                    v-model="company.state")
                  span.form-text.invalid.absolute {{ 'state' | errorText }}
              .col-md-6
                .form-group
                  label {{ 'pages.setup_wizard.country' | translate}}
                  v-select(
                    name="country"
                    single
                    hideBottomMargin
                    trackBy="isoTitle"
                    :placeholder="'ui.inputs.select_country' | translate"
                    :options="countriesList"
                    v-model="company.country")
                  span.form-text.invalid.absolute {{ 'countryName' | errorText }}

      .kt-form__actions
        .position-right
          v-btn(@click="nextStep" :loading="loading")
            | {{ isSetupPage ? 'ui.buttons.next_step' : 'ui.buttons.save' | translate}}
            i.fa.fa-arrow-right(v-if="isSetupPage")
</template>

<script>
import api from '@/api'
import VImageSelect from '@/components/ui/v-image-select/VImageSelect'
import helpers from '@/util/helpers'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CompanyForm',

  components: {
    VImageSelect
  },

  props: {
    isSetupPage: Boolean
  },

  data: () => ({
    company: {
      companyName: '',
      adminEmail: '',
      address: '',
      phoneNumber: '',
      postCode: '',
      city: '',
      state: '',
      country: {},
      defaultLanguage: {},
      companyLogoUrl: ''
    },
    fileUrl: '',
    errorUploadLogo: false,
    showCropImage: false,
    countriesList: [],
    loading: false
  }),

  async  mounted () {
    this.clearErrors()
    await this.loadCountries()
    let data = await api.getSettingCompanyInfo()
    for (let key in data) {
      if (key === 'country') {
        if (!data[key]) data[key] = {}
      }
    }

    this.company = data
  },

  methods: {
    ...mapActions('app', ['clearErrors']),

    async nextStep () {
      this.loading = true
      this.clearErrors()
      const cloneData = Object.assign({}, this.company)
      cloneData.countryId = cloneData.country ? cloneData.country.id : ''
      cloneData.defaultLanguageId = cloneData.defaultLanguage ? cloneData.defaultLanguage.id : ''
      delete cloneData.companyLogoUrl
      delete cloneData.country
      delete cloneData.defaultLanguage
      delete cloneData.countryName
      const data = await api.storeStep(cloneData)
      let resultUpdloadLogo = {}
      let file = {}

      if (this.fileUrl) {
        file = helpers.dataURLtoFile(this.fileUrl, 'Company_logo.png')
        resultUpdloadLogo = await api.uploadCompanyLogo(file)
      }

      if (resultUpdloadLogo.error) {
        this.errorUploadLogo = true
      } else {
        this.errorUploadLogo = false
      }

      this.loading = false

      if (!data.error && !this.errorUploadLogo) {
        this.$emit('on-save')
      }
    },

    async cropSuccess (imgDataUrl) {
      if (!imgDataUrl) {
        this.company.companyLogoUrl = ''
      } else {
        this.fileUrl = imgDataUrl
        this.company.companyLogoUrl = imgDataUrl
      }
    },

    async loadCountries () {
      const { data } = await api.countriesList()

      this.countriesList = data ? data.sort((a, b) => {
        let x = a.isoTitle.toUpperCase()
        let y = b.isoTitle.toUpperCase()
        return x === y ? 0 : x > y ? 1 : -1
      }) : []
    }
  },

  computed: {
    ...mapGetters('app', ['languages'])
  }
}
</script>

<style lang="scss" scoped>
  .absolute{
    position: absolute;
  }

  .invalid-logo{
    position: absolute;
    color: $color-error;
    top:100%;
    font-size: 0.9rem;
  }

  .company-pic-wrap {
    position: relative;
    display: flex;
    justify-content: center;
    width: 40%;
  }
</style>
